import { pusher } from '@/plugins/pusher';
import type { Channel } from 'pusher-js';

/**
 * Base class for WebSocket connections providing common functionality.
 */
export abstract class BaseSocket {
  protected channel: Channel | null = null;
  protected events: string[] = [];
  protected readonly channelName: string;

  /**
   * Creates an instance of BaseSocket.
   * @param {string} channelName - The full channel name to subscribe to.
   */
  constructor(channelName: string) {
    this.channelName = channelName;
    this.channel = pusher.subscribe(this.channelName);

    console.log(`Subscribed to ${this.channelName}`);
  }

  /**
   * Binds an event to the socket.
   * @param {string} event - The event to bind.
   * @param {Function} callback - The callback to execute when the event is triggered.
   */
  protected bind(event: string, callback: (data: any) => void): void {
    if (this.events.includes(event)) this.unbind(event);
    this.channel?.bind(event, callback);
    this.events.push(event);
  }

  /**
   * Unbinds all events from the socket.
   */
  public unbindAll(): void {
    if (!this.channel) return;
    this.events.forEach((event) => this.unbind(event));
    this.events = [];
  }

  /**
   * Unbinds a specific event from the socket.
   * @param {string} event - The event to unbind.
   */
  public unbind(event: string): void {
    if (!this.channel) return;
    this.channel.unbind(event);
    this.events = this.events.filter((e) => e !== event);
  }

  /**
   * Closes the WebSocket connection and cleans up resources.
   */
  public close(): void {
    if (!this.channel) return;

    this.unbindAll();
    pusher.unsubscribe(this.channelName);
    this.channel = null;
  }
}
