import axios from 'axios';

//axios.defaults.headers.common['X-Api-Token'] = import.meta.env.VITE_APP_ENGENI_API_TOKEN
//axios.defaults.headers.post['Content-Type'] = 'application/json'

export const engeniApi = axios.create({
  baseURL: import.meta.env.VITE_APP_ENGENI_API_URL,
});

engeniApi.interceptors.request.use((request) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

engeniApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
    } else {
      // Handle the case when error.response is undefined
      console.error('Error response is undefined:', error);
    }
    return Promise.reject(error);
  },
);
