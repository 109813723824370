import { queryClient } from '@/plugins/vue-query';
import { defineStore } from 'pinia';
import { useAuthStore } from './auth.store';
import { flokService } from '@/services/flok.service';
import { type Ecommerce } from '@/interfaces/ecommerce.interface';
import { useStorage } from '@vueuse/core';
import type { MaybeRef } from 'vue';
import type {
  AuthorizeWithCode,
  AuthorizeWithToken,
  CreatePlanSubscriptionApprovalDTO,
  UpdateEcommerceDTO,
} from '@/services/flok.service.interface';
import { type ConfigurationTask } from '@/interfaces/configurationTask.interface';
import * as VueGtag from 'vue-gtag';
import { isProduction } from '@/utils/environment.utils';
import type { PlanSubscriptionApproval } from '@/interfaces/planSubscriptionApproval.interface';
import { EcommerceSocket } from '@/sockets/ecommerce.socket';

interface EcommerceState {
  ecommerces: Ecommerce[];
  isLoadingEcommerces: boolean;
  current_ecommerce_id?: MaybeRef<number>;
  configuration_tasks: ConfigurationTask[];
  plan_subscription_approvals: PlanSubscriptionApproval[];
  ecommerceSocket?: EcommerceSocket;
}

export const useEcommerceStore = defineStore('ecommerce', {
  state: (): EcommerceState => ({
    ecommerces: [],
    isLoadingEcommerces: false,
    current_ecommerce_id: useStorage<number>('current_ecommerce_id', 0, localStorage),
    configuration_tasks: [],
    plan_subscription_approvals: [],
    ecommerceSocket: undefined,
  }),
  getters: {
    currentEcommerce: (state) => {
      return state.ecommerces.find((ecommerce) => ecommerce.id === state.current_ecommerce_id);
    },
  },
  actions: {
    async init() {
      //must await
      await this.getEcommerces();
      await this.getConfigurationTasks();

      //no need to await
      this.getPlanSubscriptionApprovals();
      this.subscribeToEcommerceSocket();
    },
    async getEcommerces() {
      const authStore = useAuthStore();

      if (!authStore.currentAccount?.id) return;

      try {
        this.isLoadingEcommerces = true;
        const { data } = await queryClient.ensureQueryData({
          queryKey: ['ecommerces', authStore.currentAccount?.id],
          meta: { persist: true },
          queryFn: async () => {
            const request = await flokService.getEcommercesByAccountId(
              authStore.currentAccount?.id as number,
            );
            queryClient.setQueryData(['ecommerces', authStore.currentAccount?.id], request);
            return request;
          },
        });

        this.ecommerces = data.data;

        this.current_ecommerce_id = this.ecommerces.find(
          (e) => e.order_id === authStore.user?.current_order_id,
        )?.id;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingEcommerces = false;
      }
    },
    async updateEcommerce(ecommerce_id: number, data: Partial<UpdateEcommerceDTO>) {
      await flokService.updateEcommerce(ecommerce_id, data);
      await queryClient.removeQueries({ queryKey: ['ecommerces'] });
      await this.getEcommerces();
    },
    async setSelectedEcommerce(ecommerce_id: number) {
      const authStore = useAuthStore();
      const selectedEcommerce = this.ecommerces.find((ecommerce) => ecommerce.id === ecommerce_id);
      if (!selectedEcommerce?.order_id) throw new Error('Order id is not set.');
      await authStore.setCurrentOrder(selectedEcommerce?.order_id);
      this.current_ecommerce_id = ecommerce_id;
      await queryClient.removeQueries({
        queryKey: ['configuration-tasks', this.current_ecommerce_id],
      });
      await this.getConfigurationTasks();

      if (isProduction()) {
        //Set gtag ecommerce id
        VueGtag.set({ ecommerce_id: `${ecommerce_id}` });
      }
    },
    async authorizeEcommerce(ecommerce_id: number, data: AuthorizeWithCode | AuthorizeWithToken) {
      await flokService.authorizeEcommerce(ecommerce_id, data);
      await queryClient.removeQueries({
        queryKey: ['ecommerces'],
      });
      await this.getEcommerces();
    },
    async getConfigurationTasks() {
      try {
        if (!this.current_ecommerce_id) return;
        const { data } = await queryClient.ensureQueryData({
          queryKey: ['configuration-tasks', this.current_ecommerce_id],
          meta: { persist: true },
          queryFn: () => {
            const request = flokService.getConfigurationTasks(this.current_ecommerce_id as number, {
              'configurationTask:name_contains': 'Wizard',
            });
            queryClient.setQueryData(['configuration-tasks', this.current_ecommerce_id], request);
            return request;
          },
        });

        this.configuration_tasks = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updateConfigurationTask(configuration_task_id: number, data: Partial<ConfigurationTask>) {
      if (!this.currentEcommerce) return;
      await flokService.updateConfigurationTask(
        this.currentEcommerce.id,
        configuration_task_id,
        data,
      );
      await queryClient.removeQueries({
        queryKey: ['configuration-tasks', this.current_ecommerce_id],
      });
      await this.getConfigurationTasks();
    },
    async getPlanSubscriptionApprovals() {
      if (!this.current_ecommerce_id) return;
      const { data } = await queryClient.ensureQueryData({
        queryKey: ['plan-subscription-approvals', this.current_ecommerce_id],
        queryFn: () => {
          const request = flokService.getPlanSubscriptionApprovals(
            this.current_ecommerce_id as number,
          );
          queryClient.setQueryData(
            ['plan-subscription-approvals', this.current_ecommerce_id],
            request,
          );
          return request;
        },
      });

      this.plan_subscription_approvals = data.data;
    },
    async createPlanSubscriptionApproval(data: CreatePlanSubscriptionApprovalDTO) {
      if (!this.currentEcommerce?.id) throw new Error('Ecommerce is not set.');
      await flokService.createPlanSubscriptionApproval(this.currentEcommerce?.id, data);

      await queryClient.removeQueries({ queryKey: ['plan-subscription-approvals'] });
      await this.getPlanSubscriptionApprovals();
    },
    async clear() {
      await queryClient.removeQueries({
        queryKey: ['configuration-tasks'],
      });
      await queryClient.removeQueries({
        queryKey: ['ecommerces'],
      });
      await queryClient.removeQueries({
        queryKey: ['plan-subscription-approvals'],
      });
      this.unsubscribeFromEcommerceSocket();
      this.current_ecommerce_id = 0;
      this.ecommerces = [];
    },
    subscribeToEcommerceSocket() {
      if (!this.current_ecommerce_id) return;
      this.ecommerceSocket = new EcommerceSocket(this.current_ecommerce_id);
    },
    unsubscribeFromEcommerceSocket() {
      if (!this.ecommerceSocket) return;
      this.ecommerceSocket.close();
      this.ecommerceSocket = undefined;
    },
  },
});
