<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue';

interface Props {
  texts: string[];
  interval?: number;
}

const props = withDefaults(defineProps<Props>(), {
  interval: 6000,
});

const currentTextIndex = ref<number>(0);
const intervalId = ref<ReturnType<typeof setInterval> | null>(null);

const currentText = computed<string>(() => props.texts[currentTextIndex.value]);

const rotateText = () => {
  currentTextIndex.value = (currentTextIndex.value + 1) % props.texts.length;
};

onMounted(() => {
  intervalId.value = setInterval(rotateText, props.interval);
});

onUnmounted(() => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
  }
});
</script>

<template>
  <div class="text-center">
    <Transition name="text" mode="out-in">
      <p :key="currentTextIndex">
        {{ currentText }}
      </p>
    </Transition>
  </div>
</template>

<style scoped>
.text-enter-active,
.text-leave-active {
  transition: 300ms ease;
}
.text-enter-from {
  transform: translateY(-5px);
}

.text-leave-to {
  transform: translateY(5px);
}
.text-enter-from,
.text-leave-to {
  opacity: 0;
}
</style>
