<script setup lang="ts">
import ConfirmModal from './components/common/ConfirmModal.vue';
import PlanSubscriptionApprovalModal from './components/plan-subscription-approval/PlanSubscriptionApprovalModal.vue';
import ScreenLoader from './components/common/ScreenLoader.vue';
import SupportDrawer from './components/support/SupportDrawer.vue';
import ToastContainer from './components/toast/ToastContainer.vue';
import ProductSuggestionsDrawer from './components/product/ProductSuggestionsDrawer.vue';
const { isLoading } = useUi();

const internalLoading = ref(false);

onMounted(() => {
  setTimeout(() => {
    internalLoading.value = isLoading.value;
  }, 1000);
});

watch(isLoading, (newVal) => {
  internalLoading.value = newVal;
});
</script>

<template>
  <div
    v-show="internalLoading"
    class="flex size-full h-screen items-center justify-center opacity-0 transition-opacity delay-500 duration-500"
    :class="{ 'opacity-100': internalLoading }"
  >
    <div class="flex flex-col items-center justify-center gap-3">
      <ScreenLoader />
    </div>
  </div>
  <RouterView v-slot="{ Component }">
    <component v-if="!internalLoading" :is="Component"></component>
  </RouterView>

  <!-- Global components -->
  <ToastContainer />
  <SupportDrawer />
  <PlanSubscriptionApprovalModal />
  <ConfirmModal />
  <ProductSuggestionsDrawer />
</template>

<style lang="scss"></style>
