<script setup lang="ts">
interface Props {
  message: string;
  type?: 'success' | 'error' | 'info' | 'warning' | 'loading';
  transparent?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'info',
  transparent: false,
});

const icon = computed(() => {
  switch (props.type) {
    case 'success':
      return 'heroicons:check-circle';
    case 'error':
      return 'heroicons:x-circle';
    case 'info':
      return 'heroicons:information-circle';
    case 'warning':
      return 'heroicons:exclamation-triangle';
    case 'loading':
      return 'mingcute:loading-line';
    default:
      return 'heroicons:information-circle';
  }
});
</script>

<template>
  <div
    :class="{
      'bg-system-success-light': type === 'success',
      'bg-system-error-light': type === 'error',
      'bg-brand-primary-soft': type === 'info',
      'bg-system-warning-light': type === 'warning',
      'bg-semantic-background-primary': type === 'loading',
      '!bg-transparent !px-0 !py-2': transparent,
    }"
    class="inline-block rounded-md bg-brand-primary-soft p-4 text-sm/6"
  >
    <div class="flex">
      <div class="shrink-0">
        <IconifyIcon
          :class="{
            'text-system-success-green': type === 'success',
            'text-system-error-red': type === 'error',
            'text-brand-primary': type === 'info',
            'text-system-warning-yellow': type === 'warning',
            'animate-spin text-brand-primary': type === 'loading',
          }"
          :icon="icon"
          class="mt-0.5 size-5"
          aria-hidden="true"
        />
      </div>
      <div
        :class="{
          'text-system-success-dark': type === 'success',
          'text-system-error-dark': type === 'error',
          'text-brand-primary-dark': type === 'info' || type === 'loading',
          'text-system-warning-dark': type === 'warning',
        }"
        class="ml-2 flex-1 md:flex md:justify-between"
      >
        <p class="font-medium">
          {{ message }}
        </p>
      </div>
    </div>
  </div>
</template>
