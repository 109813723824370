<script setup lang="ts">
import { useProductSuggestionsStore } from '@/stores/productSuggestions.store';
import DrawerDialog from '../common/DrawerDialog.vue';
import SmartIcon from '../common/SmartIcon.vue';
import RotatingText from '../common/RotatingText.vue';
import InlineAlert from '../common/InlineAlert.vue';
import { formatNumber } from '@/utils/format.utils';
import Badge from '../common/Badge.vue';
import ScreenLoader from '../common/ScreenLoader.vue';

const { t } = useTranslations();

const productSuggestionsStore = useProductSuggestionsStore();

const { suggestionsByCategory, product, image, isReady, isProcessing, isDraft, isError } =
  useProductSuggestions(() => productSuggestionsStore.productId);

const priceRange = computed(() => {
  const prices = product.value?.payload.variants.map((variant) => parseFloat(variant.price));

  if (!prices) return '';

  const minPrice = formatNumber(Math.min(...prices), {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  const maxPrice = formatNumber(Math.max(...prices), {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return minPrice === maxPrice
    ? `${product.value?.currency} ${minPrice}`
    : `${product.value?.currency} ${minPrice} - ${product.value?.currency} ${maxPrice}`;
});
</script>

<template>
  <DrawerDialog v-model="productSuggestionsStore.drawerOpen" size="5xl">
    <template #header>
      <h3 class="flex items-center gap-2 text-lg font-medium">
        <SmartIcon class="hidden !size-5 md:block" />
        {{ t('suggestions.title', { product: product?.title }) }}
      </h3>
    </template>
    <template #default>
      <template v-if="isReady">
        <InlineAlert type="success" class="mb-5 w-full" :message="t('suggestions.success_alert')" />
        <div class="gap-5 md:flex md:flex-row-reverse">
          <div class="md:w-2/5">
            <img
              :src="image"
              alt=""
              class="w-full max-w-full rounded-md ring-1 ring-semantic-border-primary"
            />
            <div class="mt-4 space-y-2">
              <div>
                <h3 class="text-lg font-medium">{{ product?.title }}</h3>
                <a
                  :href="product?.link"
                  :title="product?.link"
                  target="_blank"
                  class="underline-offset-1 hover:underline"
                >
                  <span class="flex items-center gap-1 text-xs text-semantic-text-secondary">
                    #{{ product?.external_id }}
                    <IconifyIcon icon="heroicons:arrow-top-right-on-square-16-solid" />
                  </span>
                </a>
              </div>
              <p class="text-brand-primary">
                {{ priceRange }}
              </p>
              <div v-if="product?.payload?.categories?.length != 0">
                <p class="mb-2">{{ t('suggestions.categories') }}</p>
                <div class="flex flex-row flex-wrap gap-2 text-sm text-semantic-text-secondary">
                  <Badge
                    v-for="category in product?.payload.categories"
                    :key="category.id"
                    color="gray"
                    >{{ category.name.es }}</Badge
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 space-y-5 md:mt-0 md:w-3/5">
            <div v-for="(item, key, index) in suggestionsByCategory" :key="key">
              <h3 class="flex items-center gap-2 text-lg font-medium text-brand-primary">
                <span
                  class="flex size-6 items-center justify-center rounded-full border border-brand-primary text-sm text-brand-primary"
                  >{{ index + 1 }}</span
                >
                {{ key }}
              </h3>
              <div class="ml-8 mt-2" v-for="suggestion in item" :key="suggestion.title">
                <div class="flex items-center gap-2">
                  <div class="flex flex-col">
                    <p class="font-medium">{{ suggestion.title }}</p>
                    <p class="text-sm text-semantic-text-secondary">
                      {{ suggestion.suggestion }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="isError">
        <div class="flex flex-col items-center justify-center gap-y-5"></div>
      </template>
      <template v-else-if="isProcessing || isDraft">
        <div class="flex size-full flex-col items-center justify-center gap-y-5">
          <div class="w-full">
            <InlineAlert type="info" class="w-full" :message="t('suggestions.loading_alert')" />
          </div>
          <div class="flex size-full flex-col items-center justify-center gap-y-5">
            <div class="animation-levitate">
              <img class="h-44 xl:h-60" src="/images/robot/robot-sleep.png" />
            </div>
            <RotatingText
              class="mt-5 text-lg font-medium text-brand-primary"
              :texts="
                isDraft
                  ? [t('suggestions.sending')]
                  : [
                      t('suggestions.loading_texts.0'),
                      t('suggestions.loading_texts.1'),
                      t('suggestions.loading_texts.2'),
                      t('suggestions.loading_texts.3'),
                    ]
              "
            />
          </div>
        </div>
      </template>
      <template v-else>
        <ScreenLoader />
      </template>
    </template>
  </DrawerDialog>
</template>

<style scoped lang="scss">
.animation-levitate {
  animation-name: levitate;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 7000ms;
  animation-fill-mode: forwards;
  transform: translateY(15px);
  &.-delayed-1 {
    animation-delay: 2800ms;
  }

  &.-delayed-2 {
    animation-delay: 1700ms;
  }
}

@keyframes levitate {
  50% {
    transform: translateY(-5px);
  }
}
</style>
