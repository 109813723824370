import { defineStore } from 'pinia';
import { useEcommerceStore } from './ecommerce.store';
import { toast } from '@/utils/toast.utils';

interface ProductSuggestionsState {
  drawerOpen: boolean;
  productId?: number;
}

export const useProductSuggestionsStore = defineStore('productSuggestions', {
  state: (): ProductSuggestionsState => ({
    drawerOpen: false,
    productId: undefined,
  }),
  getters: {},
  actions: {
    closeDrawer() {
      this.drawerOpen = false;
    },
    openDrawer() {
      this.drawerOpen = true;
    },
    toggleDrawer() {
      this.drawerOpen = !this.drawerOpen;
    },
    setProductId(productId: number) {
      this.productId = productId;
      const ecommerceStore = useEcommerceStore();

      if (!ecommerceStore.ecommerceSocket) return;
      ecommerceStore.ecommerceSocket.onSuggestionProgress((data) => {
        if (data.product_id === productId && data.step === 'done' && !this.drawerOpen) {
          toast.success({
            title: 'Ya terminamos de analizar tu producto',
            message: 'Revisa las sugerencias y mejora tu conversión 💫​',
            duration: Infinity,
            props: {
              actions: [
                {
                  text: 'Ver sugerencias',
                  onClick: () => {
                    this.openDrawer();
                  },
                },
              ],
            },
          });
        }
      });
    },
  },
});
