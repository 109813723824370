import { EcommerceSettingsPlanType } from '@/interfaces/ecommerce.interface';
import { i18n } from '@/plugins/i18n';
import { useAuthStore } from '@/stores/auth.store';
import { useEcommerceStore } from '@/stores/ecommerce.store';
import { useUiStore } from '@/stores/ui.store';
import { handleAxiosError } from '@/utils/error.utils';
import { getAccountStrategy } from '@/utils/middleware.utils';
import { toast } from '@/utils/toast.utils';
import type { NavigationGuard } from 'vue-router';

export const authMiddlewares: Record<string, NavigationGuard> = {
  isGeoAccount: async (_to, _, next) => {
    const authStore = useAuthStore();

    if (authStore.isFlokeeAccount) return next({ name: 'DashboardView' });

    next();
  },
  isFlokeeAccount: async (_to, _, next) => {
    const authStore = useAuthStore();

    if (authStore.isGeoAccount) return next({ name: 'GeoDashboardView' });

    next();
  },
  shouldRedirectToDashboard: async (_to, _, next) => {
    const authStore = useAuthStore();
    const uiStore = useUiStore();

    uiStore.setLoading(true);

    if (authStore.access_token) {
      uiStore.setLoading(false);
      if (authStore.isFlokeeAccount) return next({ name: 'DashboardView' });
      if (authStore.isGeoAccount) return next({ name: 'GeoDashboardView' });
    }

    uiStore.setLoading(false);

    next();
  },
  isAuthenticated: async (_to, _, next) => {
    const authStore = useAuthStore();
    const uiStore = useUiStore();
    const { t } = i18n.global as any;

    uiStore.setLoading(true);
    if (!authStore.access_token) {
      uiStore.setLoading(false);
      return next({ name: 'AuthLoginView' });
    }

    if (!authStore.user) {
      try {
        await authStore.invalidate();
        await authStore.getCurrentUser();
      } catch (error) {
        localStorage.clear();
        authStore.access_token = undefined;
        toast.error({ message: t('notifications.unauthenticated_error') });
        uiStore.setLoading(false);
        return next({ name: 'AuthLoginView' });
      }
    }

    uiStore.setLoading(false);

    next();
  },
  isAuthenticatedGeo: async (_to, _, next) => {
    const { t } = i18n.global as any;
    const authStore = useAuthStore();
    const uiStore = useUiStore();

    uiStore.setLoading(true);
    if (!authStore.access_token) {
      uiStore.setLoading(false);
      return next({ name: 'GeoAuthLoginView' });
    }

    if (!authStore.user) {
      try {
        await authStore.invalidate();
        await authStore.getCurrentUser();
      } catch (error) {
        localStorage.clear();
        authStore.access_token = undefined;
        toast.error({ message: t('notifications.unauthenticated_error') });
        uiStore.setLoading(false);
        return next({ name: 'GeoAuthLoginView' });
      }
    }

    uiStore.setLoading(false);

    next();
  },
  isAccountConfigured: async (_to, _, next) => {
    const authStore = useAuthStore();

    if (!authStore.user) {
      authStore.logout();
      return next({ name: 'AuthLoginView' });
    }

    const strategy = getAccountStrategy();

    // Execute validation steps
    const accountRedirect = await strategy.validateAccount();
    if (accountRedirect) {
      return next({ name: accountRedirect });
    }

    const isInvalidOrder =
      !authStore.user.current_order_id ||
      (authStore.user.current_order &&
        !authStore.user.current_order.tags.includes(authStore.isFlokeeAccount ? 'flokee' : 'geo'));

    if (isInvalidOrder) {
      const orderRedirect = await strategy.handleNoCurrentOrder();
      if (orderRedirect) {
        return next({ name: orderRedirect });
      }
    }

    if (strategy.validateIntegration) {
      const integrationRedirect = await strategy.validateIntegration();
      if (integrationRedirect) {
        return next({ name: integrationRedirect });
      }
    }

    return next();
  },
  verifyAuthentication: async (_to, _, next) => {
    const authStore = useAuthStore();
    const uiStore = useUiStore();
    uiStore.setLoading(true);

    if (!authStore.user && authStore.access_token) {
      try {
        await authStore.getCurrentUser();
      } catch (error) {
        const { message } = handleAxiosError(error);
        toast.error({ message });
      }
    }

    uiStore.setLoading(false);

    next();
  },
  isAdmin: async (_to, _, next) => {
    const authStore = useAuthStore();

    if (!authStore.user && authStore.access_token) {
      return next({ name: 'AuthLoginView' });
    }

    if (authStore.user?.role.id !== 1 && authStore.user?.role.id !== 3) {
      return next({ name: 'AuthLoginView' });
    }

    next();
  },
  canAccessBilling: async (_to, from, next) => {
    const authStore = useAuthStore();
    const ecommerceStore = useEcommerceStore();
    const { t } = i18n.global as any;

    //If is flokee account and is not paid, cant access billing
    if (authStore.isFlokeeAccount) {
      if (
        ecommerceStore.currentEcommerce?.settings.flokee_plan_type ===
        EcommerceSettingsPlanType.PAID
      ) {
        return next();
      }

      toast.error({ message: t('notifications.must_purchase_active_plan') });
      return next({ path: from.path });
    }
    //if is geo account, can always access billing
    if (authStore.isGeoAccount) {
      return next();
    }

    next();
  },
};
