export function truncateWord(word: string, count: number) {
  if (word.length > count) {
    return `${word.substring(0, count)}...`;
  } else {
    return word;
  }
}

export function formatPrice(price: number, currencyCode: string = 'ARS', locale: string = 'es-AR') {
  const numberFormat = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'code',
    minimumFractionDigits: 2,
  });

  return numberFormat.format(price / 100);
}

export function formatNumber(
  number: number,
  {
    minimumFractionDigits = 0,
    maximumFractionDigits = 0,
    locale = 'es-AR' /*navigator.language*/,
  }: { minimumFractionDigits?: number; maximumFractionDigits?: number; locale?: string } = {},
): string {
  const numberFormat = new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  });

  // Format the number
  return numberFormat.format(number);
}

export function truncateText(text: string, count: number) {
  const words = text.split(/\s+/);
  return words.slice(0, count).join(' ') + (words.length > count ? ' ...' : '');
}
