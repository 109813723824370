import type { Image } from './image.interface';

export enum EcommerceType {
  TIENDANUBE = 'TIENDANUBE',
  MERCADO_SHOP = 'MERCADO_SHOP',
  SHOPIFY = 'SHOPIFY',
}

export enum EcommerceStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  INACTIVE = 'INACTIVE',
  TEST = 'TEST',
}

export interface Ecommerce {
  id: number;
  name: string;
  type: EcommerceType;
  status: EcommerceStatus;
  media_status: string;
  account_id: number;
  order_id: number;
  website_url: string;
  integration?: EcommerceIntegration;
  settings: EcommerceSettings;
  google_ads_integration?: EcommerceGoogleAdsIntegration;
  google_analytics_integration?: EcommerceGoogleAnalyticsIntegration;
  google_merchant_integration?: EcommerceGoogleMerchantIntegration;
  google_tag_manager_integration?: EcommerceGoogleTagManagerIntegration;
  logo?: Image;
}

export enum EcommerceIntegrationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  FAILED = 'FAILED',
}

export enum EcommerceIntegrationSyncStatus {
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  SYNCED = 'SYNCED',
  NOT_SYNCED = 'NOT_SYNCED',
}

export interface EcommerceIntegration {
  id: number;
  ecommerce_id: number;
  provider_id: number;
  status: EcommerceIntegrationStatus;
  last_products_sync: Date;
  products_sync_status: EcommerceIntegrationSyncStatus;
  last_orders_sync: Date;
  orders_sync_status: EcommerceIntegrationSyncStatus;
  log?: string;
  created_at: Date;
  updated_at: Date;
}

export interface EcommerceGoogleAdsIntegration {
  id: number;
  ecommerce_id: number;
  status: string;
  ads_customer_id: string;
  webpage_purchase_global_tag: null;
  webpage_purchase_event_tag: null;
  webpage_add_to_cart_global_tag: null;
  webpage_add_to_cart_event_tag: null;
  log: null;
  created_at: Date;
  updated_at: Date;
}

export enum EcommerceGoogleIntegrationStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
}

export interface EcommerceGoogleAnalyticsIntegration {
  id: number;
  ecommerce_id: number;
  status: EcommerceGoogleIntegrationStatus;
  analytics_property_id: string;
  analytics_data_stream_id: string;
  analytics_measurement_id: string;
  account_external: boolean;
  log: string;
  created_at: Date;
  updated_at: Date;
}

export interface EcommerceGoogleMerchantIntegration {
  id: number;
  ecommerce_id: number;
  status: EcommerceGoogleIntegrationStatus;
  merchant_mca: string;
  datafeed_id: string;
  account_external: boolean;
  verification_meta_tag?: string;
  log: string;
  created_at: Date;
  updated_at: Date;
}

export interface EcommerceGoogleTagManagerIntegration {
  id: number;
  ecommerce_id: number;
  status: EcommerceGoogleIntegrationStatus;
  tag_manager_container_id: string;
  tag_manager_container_public_id: string;
  tag_manager_workspace_id: null;
  checkout_trigger_id: null;
  purchase_tag_id: null;
  google_tag_id: null;
  variable_currency_id: null;
  variable_value_purchase_id: null;
  variable_transaction_id: null;
  account_external: boolean;
  log: string;
  created_at: Date;
  updated_at: Date;
}

export enum EcommerceSettingsPlanType {
  PAID = 'PAID',
  TRIAL = 'TRIAL',
}

export interface EcommerceSettings {
  discriminate_traffic_in_stats: boolean;
  flokee_plan_type: EcommerceSettingsPlanType;
  updated_at: Date;
}
