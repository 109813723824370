import { queryClient } from '@/plugins/vue-query';
import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth.store';
import { useStorage } from '@vueuse/core';
import type { MaybeRef } from 'vue';
import * as VueGtag from 'vue-gtag';
import { isProduction } from '@/utils/environment.utils';
import type { Landing } from '../interfaces/landing.interface';
import { lkitService } from '../services/lkit.service';

interface LandingState {
  landings: Landing[];
  isLoadingLandings: boolean;
  current_landing_id?: MaybeRef<number>;
}

export const useLandingStore = defineStore('landing', {
  state: (): LandingState => ({
    landings: [],
    isLoadingLandings: false,
    current_landing_id: useStorage<number>('current_landing_id', 0, localStorage),
  }),
  getters: {
    currentLanding: (state) => {
      return state.landings.find((landing) => landing.id === state.current_landing_id);
    },
  },
  actions: {
    async init() {
      //must await
      await this.getLandings();
    },
    async getLandings() {
      const authStore = useAuthStore();

      if (!authStore.currentAccount?.id) return;

      try {
        this.isLoadingLandings = true;
        const { data } = await queryClient.ensureQueryData({
          queryKey: ['landings', authStore.currentAccount?.id],
          meta: { persist: true },
          queryFn: async () => {
            const request = await lkitService.getLandingsByAccountId(
              authStore.currentAccount?.id as number,
            );
            queryClient.setQueryData(['landings', authStore.currentAccount?.id], request);
            return request;
          },
        });

        this.landings = data.data;

        this.current_landing_id = this.landings.find(
          (l) => l.order_id === authStore.user?.current_order_id,
        )?.id;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingLandings = false;
      }
    },
    async setSelectedLanding(landing_id: number) {
      const authStore = useAuthStore();
      const selectedLanding = this.landings.find((landing) => landing.id === landing_id);
      if (!selectedLanding?.order_id) throw new Error('Order id is not set.');
      await authStore.setCurrentOrder(selectedLanding?.order_id);
      this.current_landing_id = landing_id;

      if (isProduction()) {
        //Set gtag landing id
        VueGtag.set({ landing_id: `${landing_id}` });
      }
    },
  },
});
