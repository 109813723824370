import type { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '',
    component: () => import('@/modules/nps/layouts/NpsLayout.vue'),
    children: [
      {
        path: 'poll',
        name: 'NpsPollView',
        props: (route) => ({
          poll_id: Number(route.query.poll_id),
          score: Number(route.query.score),
        }),
        component: () => import('@/modules/nps/views/NpsPollView.vue'),
      },
      {
        path: 'thanks',
        name: 'NpsThanksView',
        component: () => import('@/modules/nps/views/NpsThanksView.vue'),
      },
      {
        path: 'error',
        name: 'NpsErrorView',
        component: () => import('@/modules/nps/views/NpsErrorView.vue'),
      },
    ],
  },
] satisfies RouteRecordRaw[];
