import { authMiddlewares } from '@/middlewares/auth.middlewares';
import type { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '',
    component: () => import('@/modules/geo/layouts/DashboardLayout.vue'),
    beforeEnter: [
      authMiddlewares.isAuthenticatedGeo,
      authMiddlewares.isAccountConfigured,
      authMiddlewares.isGeoAccount,
    ],
    children: [
      {
        path: '',
        name: 'GeoDashboardView',
        component: () => import('@/modules/geo/views/common/DashboardView.vue'),
      },
      {
        path: 'interactions',
        name: 'GeoInteractionsView',
        component: () => import('@/modules/geo/views/common/InteractionsView.vue'),
      },
      {
        path: 'contacts',
        name: 'GeoContactsView',
        component: () => import('@/modules/geo/views/common/ContactsView.vue'),
      },
    ],
  },
  {
    path: 'auth',
    component: () => import('@/layouts/BlankLayout.vue'),
    redirect: 'auth/login',
    children: [
      {
        path: 'login',
        name: 'GeoAuthLoginView',
        component: () => import('@/modules/geo/views/auth/AuthLoginView.vue'),
      },
      {
        path: 'email',
        name: 'GeoAuthEmailView',
        props: (route) => ({
          email: route.query.email ?? '',
        }),
        component: () => import('@/modules/geo/views/auth/AuthEmailView.vue'),
      },
    ],
  },
] satisfies RouteRecordRaw[];
