import './assets/scss/main.scss';

import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';
import { Icon } from '@iconify/vue';
import { notivue } from './plugins/notivue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { vueQueryOptions } from './plugins/vue-query';
import { i18n } from './plugins/i18n';
import { vMaska } from 'maska/vue';
import HighchartsVue from 'highcharts-vue';
import GoogleSignInPlugin from 'vue3-google-signin';
import { vTooltip } from 'floating-vue';
import 'floating-vue/dist/style.css';
import VueGtag from 'vue-gtag';
import { createGtm } from '@gtm-support/vue-gtm';
import IntercomPlugin from './plugins/intercom';
import { isProduction } from './utils/environment.utils';
import * as Sentry from '@sentry/vue';
import { safeHtml } from './directives/safeHtml.directive';

const app = createApp(App);

//store
app.use(createPinia());

//router
app.use(router);

//components
app.component('IconifyIcon', Icon);

//directives
app.directive('mask', vMaska);
app.directive('tooltip', vTooltip);
app.directive('safe-html', safeHtml);

//plugins
app.use(i18n);
app.use(notivue);
app.use(VueQueryPlugin, vueQueryOptions);
app.use(HighchartsVue);
app.use(GoogleSignInPlugin, {
  clientId: import.meta.env.VITE_APP_GOOGLE_CLIENT_ID,
});

//Only set intercom in production
if (isProduction()) {
  app.use(IntercomPlugin, {
    api_base: import.meta.env.VITE_APP_INTERCOM_URL,
    app_id: import.meta.env.VITE_APP_INTERCOM_APP_ID,
  });
}

//Only set gtag in production
if (isProduction()) {
  app.use(
    VueGtag,
    {
      config: { id: import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID },
    },
    router,
  );
}

//Only set gtm in production
if (isProduction()) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER_ID,
      vueRouter: router,
    }),
  );
}

//Only set sentry in production
if (isProduction() && import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://cdeb38f68f4309574a657d67150ee62a@o4504477876944896.ingest.us.sentry.io/4507939892363264',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkCaptureBodies: true,
        unmask: ['*'],
        networkDetailAllowUrls: ['*'],
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/app.engeni\.com\/api/,
      /^https:\/\/app.flokee\.ai/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
app.mount('#app');
