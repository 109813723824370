import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import type { MaybeRef } from 'vue';

export const enum AppType {
  Geo = 'geo',
  Flokee = 'flokee',
}

interface AppState {
  current_app: MaybeRef<AppType | undefined>;
}

export const useAppStore = defineStore('app', {
  state: (): AppState => ({
    current_app: useStorage<AppType | undefined>('current_app', undefined, localStorage),
  }),
  getters: {},
  actions: {
    setCurrentApp(app: AppType) {
      this.current_app = app;
    },
  },
});
