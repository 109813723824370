<script setup lang="ts">
interface Props {
  isLoading?: boolean;
  size?: 'sm' | 'md' | 'square';
}

withDefaults(defineProps<Props>(), {
  size: 'md',
});

const slots = defineSlots<{
  leading(): void;
  default(): void;
  trailing(): void;
}>();
</script>

<template>
  <button
    class="flex items-center justify-center rounded-md bg-brand-primary font-medium text-white transition-all hover:bg-brand-primary-dark disabled:cursor-not-allowed disabled:bg-neutral-lightest disabled:text-semantic-text-secondary"
    :class="{
      'pointer-events-none': isLoading,
      'h-10 px-4': size === 'md',
      'h-8 px-3 text-sm': size === 'sm',
      'h-8 px-2': size === 'square',
    }"
  >
    <span v-if="!!slots.leading && !isLoading" class="mr-2 inline-block">
      <slot name="leading"></slot>
    </span>

    <slot v-if="!isLoading" name="default"></slot>

    <IconifyIcon v-if="isLoading" icon="formkit:spinner" class="size-6 animate-spin"></IconifyIcon>

    <span v-if="!!slots.trailing && !isLoading" class="ml-2 inline-block">
      <slot name="trailing"></slot>
    </span>
  </button>
</template>

<style scoped lang="scss"></style>
