import type { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '',
    component: () => import('@/modules/admin/layouts/BaseLayout.vue'),
    children: [
      {
        path: 'select-account',
        name: 'AdminSelectAccountView',
        component: () => import('@/modules/admin/views/AdminSelectAccountView.vue'),
      },
    ],
  },
] satisfies RouteRecordRaw[];
