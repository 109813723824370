export enum ProductSuggestionStatus {
  Draft = 'DRAFT',
  Processing = 'PROCESSING',
  Done = 'DONE',
  Error = 'ERROR',
}

export interface ProductSuggestion {
  status: ProductSuggestionStatus;
  ecommerce_id: number;
  product_id: number;
  suggestions: ProductSuggestionItem[];
  updated_at: Date;
  created_at: Date;
  id: number;
  product: ProductSuggestionProduct;
  image: string;
}

export interface ProductSuggestionItem {
  category: string;
  title: string;
  suggestion: string;
}
export interface ProductSuggestionProduct {
  id: number;
  ecommerce_id: number;
  external_id: string;
  title: string;
  description: string;
  status: string;
  link: string;
  image_link: string;
  currency: string;
  brand: string;
  condition: string;
  adult: number;
  meta: ProductSuggestionMeta;
  payload: ProductSuggestionPayload;
  created_at: Date;
  updated_at: Date;
  deleted_at: null;
}

export interface ProductSuggestionMeta {
  images: ProductSuggestionMetaImage[];
  categories: ProductSuggestionCategory[];
}

export interface ProductSuggestionCategory {
  id: number;
  name: ProductSuggestionDescription;
  handle: ProductSuggestionDescription;
  parent: number | null;
  seo_title: ProductSuggestionDescription;
  created_at: string;
  updated_at: string;
  description: ProductSuggestionDescription;
  subcategories: number[];
  seo_description: ProductSuggestionDescription;
  google_shopping_category: string;
}

export interface ProductSuggestionDescription {
  en: string;
  es: string;
}

export interface ProductSuggestionMetaImage {
  url: string;
}

export interface ProductSuggestionPayload {
  id: number;
  name: ProductSuggestionDescription;
  tags: string;
  brand: string;
  handle: ProductSuggestionDescription;
  images: ProductSuggestionPayloadImage[];
  variants: ProductSuggestionVariant[];
  published: boolean;
  seo_title: ProductSuggestionDescription;
  video_url: null;
  attributes: ProductSuggestionDescription[];
  categories: ProductSuggestionCategory[];
  created_at: string;
  updated_at: string;
  description: ProductSuggestionDescription;
  canonical_url: string;
  free_shipping: boolean;
  seo_description: ProductSuggestionDescription;
  requires_shipping: boolean;
}

export interface ProductSuggestionPayloadImage {
  id: number;
  alt: any[];
  src: string;
  width: number;
  height: number;
  position: number;
  created_at: string;
  product_id: number;
  updated_at: string;
  thumbnails_generated: number;
}

export interface ProductSuggestionVariant {
  id: number;
  mpn: null;
  sku: null | string;
  cost: null;
  depth: string;
  price: string;
  stock: number;
  width: string;
  gender: null;
  height: string;
  values: ProductSuggestionDescription[];
  weight: string;
  barcode: null;
  image_id: number;
  position: number;
  age_group: null;
  created_at: string;
  product_id: number;
  updated_at: string;
  compare_at_price: string;
  inventory_levels: ProductSuggestionInventoryLevel[];
  stock_management: boolean;
  promotional_price: string;
}

export interface ProductSuggestionInventoryLevel {
  id: number;
  stock: number;
  variant_id: number;
  location_id: string;
}
