import { engeniApi } from '@/api/engeni.api';
import type { ApiResponseData, ApiResponsePaginated } from '@/interfaces/api.interface';
import type { Landing } from '@/modules/geo/interfaces/landing.interface';
import type { Contact } from '../interfaces/contact.interface';
import type { Event } from '../interfaces/event.interface';
import type { LandingStats, LandingSummaryStats } from '../interfaces/landingStats.interface';
import type { FormConfiguration } from '../interfaces/formConfiguration.interface';
import type { Form } from '../interfaces/form.interface';
import type { ContactChannel } from '../interfaces/contactChannel.interface';

const BASE_PATH = '/lkit';
//const BASE_PUBLIC_PATH = `/public${BASE_PATH}`;

export const lkitService = {
  async getLandingsByAccountId(account_id: number) {
    return await engeniApi.get<ApiResponseData<Landing[]>>(
      `${BASE_PATH}/landings/?account_id=${account_id}&no_pagination=1&embed=forms`,
    );
  },
  async getLandingEvents(
    landing_id: number,
    filter?: Record<string, any>,
    pagination?: { page: number; per_page: number },
  ) {
    return await engeniApi.get<ApiResponsePaginated<Event[]>>(
      `${BASE_PATH}/landings/${landing_id}/events?sort=-id&range=600`,
      {
        params: { ...filter, ...pagination },
      },
    );
  },
  async getContactChannels(account_id: number, filter?: Record<string, any>) {
    return await engeniApi.get<ApiResponseData<ContactChannel[]>>(
      `${BASE_PATH}/contact-channels?account_id=${account_id}&no_pagination=1&embed=action_type`,
      {
        params: { ...filter },
      },
    );
  },
  async getLandingStats(landing_id: number, filter?: Record<string, any>) {
    return await engeniApi.get<ApiResponseData<LandingStats<LandingSummaryStats>>>(
      `${BASE_PATH}/landings/${landing_id}/stats`,
      {
        params: { ...filter },
      },
    );
  },
  async getLandingContacts(
    landing_id: number,
    filter?: Record<string, any>,
    pagination?: { page: number; per_page: number },
  ) {
    return await engeniApi.get<ApiResponsePaginated<Contact[]>>(
      `${BASE_PATH}/contacts?landing_id=${landing_id}&sort=-id`,
      {
        params: { ...filter, ...pagination },
      },
    );
  },
  async exportContacts(landing_id: number) {
    return await engeniApi.get<any>(`${BASE_PATH}/contacts/csv?landing_id=${landing_id}`);
  },
  async getContactByEventId(event_id: number) {
    return await engeniApi.get<ApiResponseData<Contact[]>>(
      `${BASE_PATH}/contacts?event_id=${event_id}&no_pagination=1`,
    );
  },
  async getFormConfigurationById(form_configuration_id: number) {
    return await engeniApi.get<ApiResponseData<FormConfiguration>>(
      `${BASE_PATH}/form-configurations/${form_configuration_id}`,
    );
  },
  async getForms() {
    return await engeniApi.get<ApiResponseData<Form[]>>(`${BASE_PATH}/forms?no_pagination=1`);
  },
};
