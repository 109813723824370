<script setup lang="ts">
interface Props {
  size?: 'sm' | 'md' | 'lg';
}

defineProps<Props>();
const slots = defineSlots<{
  leading(): void;
  default(): void;
  trailing(): void;
}>();
</script>

<template>
  <button
    :class="[
      {
        'text-sm': size === 'sm',
        'text-lg': size === 'lg',
      },
    ]"
    class="flex items-center justify-center rounded-md bg-transparent px-1 font-medium text-brand-primary transition-all hover:bg-brand-primary-soft/50 hover:text-brand-primary-dark disabled:cursor-not-allowed disabled:text-semantic-text-secondary"
  >
    <span v-if="!!slots.leading" class="mr-2 inline-block">
      <slot name="leading"></slot>
    </span>

    <slot name="default"></slot>

    <span v-if="!!slots.trailing" class="ml-2 inline-block">
      <slot name="trailing"></slot>
    </span>
  </button>
</template>

<style scoped lang="scss"></style>
