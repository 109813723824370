import type { Plan } from './plan.interface';

export enum OrderStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  INVALID = 'INVALID',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export interface Order {
  id: number;
  status: OrderStatus;
  total: string;
  activated_by: null;
  account_id: number;
  isic_id: null;
  suspension_disabled: number;
  mute_reports: number;
  created_by: number;
  updated_by: number;
  deleted_by: null;
  analyst_transcript: null;
  deleted_at: null;
  created_at: Date;
  updated_at: Date;
  activated_at: null;
  canceled_at: null;
  canceled_by: null;
  suspended_at: null;
  unsuspended_at: null;
  suspended_by: null;
  unsuspended_by: null;
  tags: string[];
  order_plans?: OrderPlan[];
}

export interface OrderPlan {
  id: number;
  order_id: number;
  plan_id: number;
  original_plan_currency: string;
  original_plan_price: string;
  original_free_trial_months: number;
  original_free_trial_percentage: number;
  left_free_trial_months: number;
  status: string;
  original_plan_ad_investment_percentage: string;
  price: string;
  price_updated_at: Date;
  price_allow_sync: number;
  created_by: null;
  updated_by: null;
  deleted_by: null;
  deleted_at: null;
  created_at: Date;
  updated_at: Date;
  plan: Plan;
  order_plan_products: OrderPlanProduct[];
}

export interface OrderPlanProduct {
  id: number;
  status: string;
  original_settings: null;
  order_plan_id: number;
  product_id: number;
  created_by: null;
  updated_by: null;
  deleted_by: null;
  deleted_at: null;
  created_at: Date;
  updated_at: Date;
  product: OrderProduct;
}

export interface OrderProduct {
  id: number;
  name: string;
  active: boolean;
  optional: boolean;
  created_at: Date;
  updated_at: Date;
  type: string;
  settings: null;
  created_by: number;
  updated_by: number;
  deleted_by: null;
  deleted_at: null;
  plan_id: number;
}
