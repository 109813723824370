import { EcommerceStatus } from '@/interfaces/ecommerce.interface';
import { LandingStatus } from '@/modules/geo/interfaces/landing.interface';
import { useLandingStore } from '@/modules/geo/stores/landing.store';
import { i18n } from '@/plugins/i18n';
import { AppType, useAppStore } from '@/stores/app.store';
import { useAuthStore } from '@/stores/auth.store';
import { useEcommerceStore } from '@/stores/ecommerce.store';
import { useUiStore } from '@/stores/ui.store';
import { toast } from '@/utils/toast.utils';

export interface AccountConfigurationStrategy {
  validateAccount: () => Promise<string | undefined>;
  handleNoCurrentOrder: () => Promise<string | undefined>;
  validateIntegration?: () => Promise<string | undefined>;
}

export function getAccountStrategy(): AccountConfigurationStrategy {
  const authStore = useAuthStore();
  const uiStore = useUiStore();
  const ecommerceStore = useEcommerceStore();
  const landingStore = useLandingStore();
  const { t } = i18n.global as any;

  const flokeeStrategy: AccountConfigurationStrategy = {
    validateAccount: async () => {
      const hasAccounts = authStore.user?.accounts && authStore.user?.accounts?.length > 0;
      if (!authStore.user?.current_account_id) {
        if (authStore.user?.role.id === 1 || authStore.user?.role.id === 3) {
          return 'AdminSelectAccountView';
        }
        return hasAccounts ? 'SelectAccountView' : 'WizardCreateAccountView';
      }

      if (!authStore.currentAccount?.active) {
        return hasAccounts ? 'SelectAccountView' : 'WizardCreateAccountView';
      }
    },
    handleNoCurrentOrder: async () => {
      if (authStore.user?.role.id === 1 || authStore.user?.role.id === 3) {
        return 'AdminSelectAccountView';
      }

      const hasEcommerces = ecommerceStore.ecommerces.length > 0;
      if (!hasEcommerces) {
        return 'WizardCreateEcommerceView';
      }

      const activeEcommerce = ecommerceStore.ecommerces.find(
        (e) => e.status === EcommerceStatus.ACTIVE,
      );

      try {
        if (activeEcommerce) {
          await ecommerceStore.setSelectedEcommerce(activeEcommerce.id);
        } else {
          await ecommerceStore.setSelectedEcommerce(ecommerceStore.ecommerces[0].id);
        }
      } catch (error) {
        toast.error({ message: t('notifications.account_not_configured_error') });
        uiStore.openSupportDrawer();
        return 'AuthLoginView';
      }
    },
    validateIntegration: async () => {
      if (ecommerceStore.currentEcommerce && !ecommerceStore.currentEcommerce?.integration) {
        if (authStore.user?.role.id === 1 || authStore.user?.role.id === 3) {
          toast.error({ message: t('notifications.ecommerce_integration_not_set_error') });
          return 'AdminSelectAccountView';
        }
        return 'WizardSelectEcommerceProviderView';
      }
    },
  };

  const geoStrategy: AccountConfigurationStrategy = {
    validateAccount: async () => {
      if (!authStore.user?.current_account_id) {
        if (authStore.user?.role.id === 1 || authStore.user?.role.id === 3) {
          return 'AdminSelectAccountView';
        }

        if (!authStore.user?.accounts || authStore.user?.accounts?.length === 0) {
          toast.error({ message: t('notifications.account_not_configured_error') });
          uiStore.openSupportDrawer();
          return 'GeoAuthLoginView';
        }

        await authStore.setCurrentAccount(authStore.user?.accounts[0].id);
      }

      if (!authStore.currentAccount?.active) {
        const activeAccounts = authStore.user?.accounts?.filter((a) => a.active);
        if (!activeAccounts || activeAccounts?.length === 0) {
          if (authStore.user?.role.id === 1 || authStore.user?.role.id === 3) {
            return 'AdminSelectAccountView';
          }

          toast.error({ message: t('notifications.account_not_configured_error') });
          uiStore.openSupportDrawer();
          return 'GeoAuthLoginView';
        }
        await authStore.setCurrentAccount(activeAccounts[0].id);
      }
    },
    handleNoCurrentOrder: async () => {
      if (authStore.user?.role.id === 1 || authStore.user?.role.id === 3) {
        return 'AdminSelectAccountView';
      }

      const hasLandings = landingStore.landings.length > 0;
      if (!hasLandings) {
        toast.error({ message: t('notifications.account_not_configured_error') });
        uiStore.openSupportDrawer();
        return 'GeoAuthLoginView';
      }

      const activeLanding = landingStore.landings.find((l) => l.status === LandingStatus.ACTIVE);

      try {
        if (activeLanding) {
          await landingStore.setSelectedLanding(activeLanding.id);
        } else {
          await landingStore.setSelectedLanding(landingStore.landings[0].id);
        }
      } catch (error) {
        toast.error({ message: t('notifications.account_not_configured_error') });
        uiStore.openSupportDrawer();
        return 'GeoAuthLoginView';
      }
    },
  };

  const appStore = useAppStore();

  const hasAccounts = authStore.user?.accounts && authStore.user?.accounts?.length > 0;

  if (!hasAccounts) {
    return appStore.current_app === AppType.Geo ? geoStrategy : flokeeStrategy;
  }

  return authStore.isGeoAccount ? geoStrategy : flokeeStrategy;
}
